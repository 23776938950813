<template>
  <div>
    <!-- 头部 -->
    <Header />
    <!-- 搜索 -->
    <Search />

    <Loading v-if="isLoading" />
    <div class="w-1920 back" v-else>
      <div class="neirong w-1200">
        <div class="title">{{ title }}</div>
        <div class="time">{{ time }}</div>
        <div class=" con" v-html="conten"></div>
      </div>
    </div>
    <Foot />
    <Cart />
  </div>
</template>
<script>
import Header from '@/components/header.vue';
import Cart from '@/components/cart.vue';
import { post, get } from '@/utils/request';
import Search from '@/components/home/search.vue';
import Foot from '@/components/foot.vue';
export default {
  components: {
    Header,
    Search,
    Cart,
    Foot,
  },
  data() {
    return {
      isLoading: true,
      conten: '',
      title: '',
      time: '',
    };
  },
  created() {
    this.getlist(this.$route.query.id);
  },
  methods: {
    getlist(e) {
      get(
        'f/api/information/selectShopIndustryInformationByid?id=' + e + ''
      ).then((res) => {
        this.isLoading = false;
        this.conten = res.data.data.content;
        this.title = res.data.data.title;
        this.time = res.data.data.createTime;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.back {
  background: #f5f5f5;
  padding: 20px 0;
  .neirong {
    background: #fff;
    padding: 20px 20px 100px 20px;
  }
  .title {
    width: 100%;
    margin: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    font-size: 27px;
    color: #e88426;
  }
  .time {
    width: 100%;
    text-align: right;
    margin-top: 20px;
    font-size: 15px;
    color: #999;
  }
  .con {
    margin-top: 10px;
    font-size: 17px;
  }
}
</style>
